.nav-layout {
  flex: 1;
  display: flex;
  flex-direction: row;

  &__nav {
    padding: 1rem;
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      
      li {

        a {
          color: #484848;
          
          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }

  &__content {
    flex: 1;
    padding: 1rem;
  }
}
