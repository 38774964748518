 .main-header {
   background-color: #000; 
   box-shadow: 2px 0 0 #ebebeb;

  &__logo {
    margin-left: -15px;
    height: 100%;
    vertical-align: middle;
  }

  .bp3-navbar-heading {
    height: 100%;
    font-weight: 600;

    a {
      display: inline-flex;
      height: 100%;
      align-items: center;
      color: inherit;
      text-decoration: none;
    }
  }
}
// login form (may need be move at another place)
.login-form {
  margin-top: 2em;
}
