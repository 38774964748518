// Override default layout style
.CRUD {
  height: 100%;
  .nav-layout {
    flex: 1 0 auto;
    height: 100%;
    &__nav {
      padding: 0;
    }
    &__content {
      padding: 0;
    }
  }
}

.CRUD-main {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}
