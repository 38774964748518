.CRUD-nav {
  display: flex;
  flex-direction: column;
  width: 6rem;
  max-height: calc(100vh - 50px);
  
  &--active {
    width: 17rem;
    .CRUD-nav__button {
      text-align: right;
      border: 0;
      padding-right: 1.5rem;
    }
  }
  
  &__button {
    padding: 0.7rem 1rem 0;
    text-align: center;
  }
  
  &__menu {
    &#{&} {
      flex: auto;
      position: relative;
      margin-top: 0.5rem;
      padding: 0.5rem 1rem 1rem;
      overflow: auto;
      border-top: 1px solid #efefef;
    }
  }

  &__group,
  &__item-content {
    .bp3-icon {
      width: 20px;
      margin-right: 7px;
    }
  }

  &__group-button {
    width: 100%;
  }

  &__popover {
    left:-20px;
  }

  &__icon {
    max-width: 100%;
  }

  &__group:not(:first-child) {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #efefef;
  }

  &__list {
    margin-left: 1rem !important;
    border-left: 1px solid #efefef;
    overflow: hidden;
  }

  &__item {
    padding: 0.25rem 0.3rem;
  }

  &__action {
    display: flex;
  }
  
  &__link {
    position: relative;
    display: inline-block;
    margin-right: auto;
    &:hover,
    &:focus {
      outline: none;
      position: relative;
      z-index: 100;
      .bp3-button {
        background: #e4e9ed !important;
      }
      .bp3-button-text {
        opacity: 1;
      }
    }
  }
}
