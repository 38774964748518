.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1 0 auto;

  &-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    > * {
      flex: 1 0 auto;
    }
  }

  &-footer {
    text-align: center;
  }

  &-container {
    display: flex;
    flex: 1 0 auto;
    align-items: stretch;
  }
  &-content {
    flex: auto;
  }
}
